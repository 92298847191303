.navbar {
  background: #232C63;
    height:80px;
    display:flex;
    color:white;
    justify-content:center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top:0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
       
    }
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  height:80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
  
  
}
.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 0px;
  list-style: none;
  text-align: center;

  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  align-items:center;

 
   
}



.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  @media screen and (max-width: 960px) {
  
    align-items: top;
   
}
}

.nav-linksOLD {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links{
  padding: 0.5rem 1rem;
  text-decoration: none;
  color:white;
  
   
}


.nav-links:hover {
 color:#F6B519;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}



@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left:-100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: black;
    
    left: 0;
    top: 64px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    display: flex;
    align-items:center;
    justify-content: center;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    margin: 2rem auto;
    width: 100%;
    display: table;
    
  }

  .nav-links:hover{
    color: #F6B519;
    border-radius: 0;
  }


  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: red;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}