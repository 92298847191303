.aboutus-dropdown-menu{
  width: 250px;
 
  top: 80px;
  list-style: none;
  text-align: start;
  z-index:35;
  position:absolute;
}

.aboutus-dropdown-menu li {
  background: #232c63;
  cursor: pointer;
  
}

.aboutus-dropdown-menu li:hover{
  background-color:#F6B519;
}

.aboutus-dropdown-menu.clicked{
  display: none;
}

.aboutus-dropdown-link{
  display: block;
  width: 100%;
  height: 100%;
  text-decoration:none;
  color: #fff;
  padding:10px;
  z-index:34;
}